@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

button {
  display: block;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  transition: 0.2s;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

%button {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  align-self: flex-start;
  align-items: center;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  padding: #{variables.$gap_s * 0.5} variables.$gap_xs;
  border: 1px solid transparent;
  border-radius: variables.$radius_small;
  color: colors.$color_white;
  font-family: variables.$font_main;
  font-size: variables.$size_normal;
  font-weight: 700;
  line-height: 1.375;
  min-height: 38px;
  text-align: center;
  vertical-align: middle;
  transition: 0.3s;
  cursor: pointer;
  -moz-appearance: none;
  @include mixins.button(colors.$color_primary);
  background: colors.$color_primary;
  -webkit-appearance: none;
  appearance: none;

  &:hover {
    color: colors.$color_white;
    text-decoration: none;
  }

  @include mixins.night {
    background-color: colors.$color-danube;
  }

  @include mixins.desktop_small {
    padding: 10px 20px;
    font-size: variables.$size_normal;
  }
}

input[type='submit'],
input[type='button'] {
  @extend %button !optional;
}

.button {
  @extend %button !optional;

  @include mixins.night {
    color: colors.$color_white;
  }

  &.is_loading {
    color: transparent;

    @include mixins.night {
      color: transparent;
    }
  }

  .wrapping-content {
    display: inline-block;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    .before {
      position: relative;
      top: 0;
      display: block;
      pointer-events: none;
      text-align: center;
      width: 100%;
    }

    .after {
      bottom: -100%;
      position: absolute;
      display: block;
      pointer-events: none;
      text-align: center;
      width: 100%;
    }
  }

  &:not([disabled]) {
    &:hover {
      .wrapping-content {
        .before {
          animation: slideUPTextBefore 0.8s forwards;
        }

        .after {
          animation: slideUPTextAfter 0.3s forwards;
        }
      }
    }
  }

  &.extra-small {
    padding: variables.$gap_xxxs variables.$gap_xxs;
    box-shadow: variables.$shadow_small;
    font-size: variables.$size_small;
    min-height: unset;
  }

  &.small {
    padding: 9px variables.$gap_xs;
    box-shadow: variables.$shadow_small;
    font-size: variables.$size_small;
    line-height: 1.6;
  }

  &.semi-small {
    padding: #{variables.$gap_s * 0.5} variables.$gap_xs;
    box-shadow: variables.$shadow_small;
    font-size: variables.$size_large;
  }

  &.large {
    padding: 20px variables.$gap_s;
    font-size: variables.$size_huge;
  }

  &.wide {
    width: 100%;
    padding-right: variables.$gap_xs;
    padding-left: variables.$gap_xs;
  }

  &.rounded {
    border-radius: 30px;
    min-height: unset;

    &.small {
      padding: variables.$gap_xxs;
    }

    &.large {
      padding: 20px;
    }

    .ico,
    .loader,
    & > i {
      margin: 0;
    }
  }

  &.outlined {
    border-color: colors.$color_primary;
    background-color: transparent;
    color: colors.$color_primary;

    @include mixins.night {
      background-color: transparent;
    }
  }

  &.secondary {
    &:not(.outlined) {
      @include mixins.button(colors.$color_primary);

      @include mixins.night {
        background-color: colors.$color-danube;
      }
    }
  }

  &.gold {
    background-color: colors.$color-potters-clay;
    color: colors.$color_white;
  }

  &.tertiary,
  &.tertiary-left-arrow,
  &.tertiary-small {
    position: relative;
    overflow: visible;
    padding: 0;
    min-height: 40px;
    background: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    font-size: variables.$size_normal;
    color: colors.$color_navigation;

    &:hover {
      .icon_arrow {
        transform: scale(-1, -1) translate(-#{variables.$gap_xxxs}, 0);
      }

      .fa-arrow-left {
        transform: scale(1, 1) translate(-#{variables.$gap_xxxs}, 0);
      }
    }

    &:focus {
      border-radius: variables.$radius_small;
    }

    @include mixins.night {
      color: colors.$color-danube;
    }

    .fa-arrow-left {
      display: inline-flex;
      align-items: center;
      width: 14px;
      font-size: variables.$size_normal;
      line-height: 20px;
      transition: 0.2s;
    }

    .icon_arrow {
      @extend %icon_arrow !optional;

      fill: currentColor;
    }

    &.white {
      background: none;
      box-shadow: none;
      color: colors.$color_white;

      &:hover {
        background: none;
        box-shadow: none;
        color: colors.$color_white;

        .icon_arrow {
          transform: scale(-1, -1) translate(-#{variables.$gap_xxxs}, 0);
        }
      }

      .icon_arrow {
        @extend %icon_arrow !optional;

        fill: currentColor;
      }
    }

    &.link {
      min-height: unset;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover {
        .icon_arrow {
          transform: scale(-1, -1) translate(-#{variables.$gap_xxxs}, 0);
        }
      }

      .icon_arrow {
        @extend %icon_arrow !optional;
      }
    }
  }

  &.tertiary-small {
    font-size: variables.$size_small;
    min-height: 32px;
  }

  &.tertiary-left-arrow {
    .icon_arrow {
      margin-left: unset !important;
      margin-right: variables.$gap_xxs;
      transform: none !important;
    }

    &:hover {
      .icon_arrow {
        transform: scale(1, 1) translate(-#{variables.$gap_xxxs}, 0) !important;
      }
    }
  }

  &.info {
    @include mixins.button(colors.$color_info);
  }

  &.white {
    @include mixins.button(colors.$color_white);

    @include mixins.night {
      background: colors.$color-mirage;
      color: colors.$color_white;
    }
  }

  &.success {
    @include mixins.button(colors.$color_success);
  }

  &.warning {
    @include mixins.button(colors.$color_warning);
    color: colors.$yellow-900;

    &.outlined {
      color: colors.$yellow-900;

      &:hover {
        color: colors.$yellow-900;
      }
    }
  }

  &.danger {
    @include mixins.button(colors.$red-600);
  }

  &.transparent {
    background: transparent;
    box-shadow: none;
    color: colors.$color_primary;
    text-decoration: underline;

    &:hover {
      color: colors.$color_info;
      text-decoration: none;
    }
  }

  &.white {
    border-color: transparent;
    background: colors.$color_white;
    color: colors.$color_primary;
  }

  &.disabled,
  &:disabled {
    border-color: colors.$color-neutral-8;
    background: colors.$color-neutral-8;
    box-shadow: none;
    color: colors.$color-neutral-6;
    cursor: default;

    @include mixins.night {
      border-color: colors.$color-mako;
      background: colors.$color-mako;
      color: colors.$color-gray-chateau;
    }
  }

  &.disabled {
    cursor: default;
  }

  &.link {
    align-self: center;
    justify-content: space-between;
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    color: colors.$color_navigation;
    font-size: variables.$size_normal;

    @include mixins.night {
      color: colors.$color-danube;
    }

    &.is_loading {
      color: transparent;

      @include mixins.night {
        color: transparent;
      }
    }

    &.wide {
      display: flex;
    }

    &.small {
      font-size: variables.$size_small;
    }

    @media (hover: hover) {
      &:focus,
      &:active,
      &:hover {
        background: transparent;
        box-shadow: none;
        text-decoration: underline;
      }
    }

    &:focus {
      outline: none;
      text-decoration: none;
    }

    .focus-visible {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$radius_small;
    }

    &.focus-visible {
      &:focus-within {
        outline: variables.$gap_xxxs solid colors.$blue-400;
        border-radius: variables.$radius_small;
      }
    }

    i {
      &:before {
        display: inline-block;
      }
    }
  }

  &.is_loading {
    min-height: auto;

    @include mixins.night {
      border: none;
    }

    &:after {
      background-color: transparent;
      background-image: url(/assets/images/ico_loader_small.svg);
      background-size: 32px 32px;
      filter: brightness(0) invert(1);

      @include mixins.night {
        background-image: url(/assets/images/ico_loader_small.svg);
      }
    }
  }

  &.outlined.is_loading {
    &:after {
      background-color: colors.$color_white;
      filter: none;
    }
  }

  &.circle {
    display: block;
    padding: 0;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: colors.$color_navigation;
    transition: all 0.2s;
    min-height: unset;
    box-shadow:
      0px 3px 6px rgba(colors.$blue-500, 0.1),
      0px 10px 20px rgba(colors.$blue-500, 0.15);

    &[disabled] {
      background: colors.$neutral-600;
      cursor: not-allowed;

      @include mixins.night {
        background: colors.$neutral-600;
      }

      &:hover {
        background: colors.$neutral-600;
      }
    }

    &:hover {
      background: colors.$color_hover;
    }

    @include mixins.night {
      background: colors.$blue-400;
    }

    & > i {
      margin: 0;
    }

    &.small {
      padding: 0;
      width: 32px;
      height: 32px;
    }

    .icon-next,
    .icon-prev {
      background-repeat: no-repeat;
      background-image: url('/assets/images/dashboard/ico_prev.svg');
      background-position: center;
      height: 24px;
    }

    .icon-next {
      background-image: url('/assets/images/dashboard/ico_next.svg');
    }

    &.inactive {
      background: colors.$neutral-600;

      &:hover {
        background: colors.$color_hover;
      }
    }
  }

  .ico,
  .loader,
  & > i {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    margin-right: variables.$gap_xxs;
    font-size: variables.$size_large;
    line-height: 24px;
    vertical-align: middle;

    &.right {
      margin-right: 0;
      margin-left: variables.$gap_xxs;
    }
  }

  .loader {
    width: 24px;
    height: 24px;
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .ico {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      background: 50% 50% no-repeat;
      background-size: contain;
    }

    &_facebook:before {
      background-image: url(/assets/images/ico_social_facebook.svg);
    }

    &_google:before {
      background-image: url(/assets/images/ico_social_google.svg);
    }

    &_microsoft:before {
      background-image: url(/assets/images/ico_social_microsoft.svg);
    }

    &_linkedin:before {
      background-image: url(/assets/images/ico_social_linkedin.svg);
    }
  }

  .fab {
    font-weight: normal;
  }

  .hide-on-phone {
    display: inline-block;
    position: relative;

    @include mixins.phone_small {
      display: none !important;
    }
  }

  @include mixins.desktop_small {
    &.small {
      padding: variables.$gap_xxxs #{variables.$gap_s * 0.5};
      font-size: variables.$size_small;
    }

    &.large {
      padding: 14px 20px;
      font-size: variables.$size_large;
    }

    &.rounded {
      padding: #{variables.$gap_s * 0.5};
      border-radius: 30px;

      &.small {
        padding: variables.$gap_xxxs;
      }

      &.large {
        padding: 14px;
      }
    }
  }

  // used for removing bold from .button.link
  &.text-normal {
    font-weight: normal;
  }

  &.icon {
    width: 32px;
    height: 32px;

    @include mixins.desktop_small {
      width: 40px;
      height: 40px;
    }

    @media (hover: hover) {
      &:hover {
        [icon='thumbs_up_filled'] {
          svg {
            stroke: none;
          }
        }
      }
    }

    [icon='thumbs_up'],
    [icon='thumbs_up_filled'] {
      svg {
        width: 16px;
        height: 16px;
        fill: colors.$color_primary;
        stroke: colors.$color_primary;

        @include mixins.night {
          fill: colors.$color-danube;
          stroke: colors.$color-danube;
        }

        @include mixins.desktop_small {
          width: 24px;
          height: 24px;
        }

        &:focus-visible {
          outline-offset: variables.$gap_xxxs;
        }
      }

      &.inverted {
        transform: scaleY(-1);
      }
    }

    [icon='thumbs_up_filled'] {
      svg {
        stroke: none !important;
      }
    }
  }
}

.buttons {
  display: block;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -#{variables.$gap_xs * 0.5};
  font-size: 0;
  line-height: 0;

  &.inline {
    display: flex;
    flex-direction: row;
  }

  &.vertical {
    display: flex;
    flex-direction: column;
  }

  &:last-child {
    margin-bottom: -#{variables.$gap_xs};
  }

  .button {
    align-self: center;
    margin: 0 #{variables.$gap_xs * 0.5} variables.$gap_xs;
    white-space: nowrap;

    &.wide {
      width: calc(100% - #{variables.$gap_xs});
    }
  }

  @include mixins.tablet_small {
    flex-wrap: nowrap;
  }

  @include mixins.desktop_small {
    .social_label {
      display: none;
    }
  }
}

.ico_close {
  display: block;
  width: 24px;
  height: 24px;
  transition: 0.2s;
  cursor: pointer;
  z-index: 2;
  stroke: colors.$color_navigation;

  @include mixins.night {
    stroke: colors.$color-neutral-1;
  }
}

.btn-close,
.close-btn {
  &:hover {
    opacity: 0.8;
  }
}

%icon_arrow {
  margin-left: variables.$gap_xxs;
  width: 14px;
  height: 14px;
  transform: scale(-1, -1);
  transition: transform 0.2s;
}

.buttons-wrapper {
  display: flex;
  margin: 0 -#{variables.$gap_xxs * 0.5};

  & > * {
    margin: 0 #{variables.$gap_xxs * 0.5};
  }
}
